import { inputBaseClasses, lighten, switchClasses } from '@mui/material';
import { alpha, Breakpoint, createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const text = {
  primary: 'rgba(0, 0, 0, 0.87)',
  secondary: 'rgba(0, 0, 0, 0.54)',
  disabled: 'rgba(0, 0, 0, 0.38)',
  hint: 'rgba(0, 0, 0, 0.38)',
};

const brandBlue = [
  '#D1D9F5',
  '#B5C4FF',
  '#849CF0',
  '#003FE2',
  '#4d61af',
  '#00145F',
  '#091146',
  '#060B2E',
  '#030617',
];

interface ThemeColor {
  light: string;
  main: string;
  dark: string;
  contrastText: string;
}
interface ThemeColors {
  mode?: 'dark' | 'light';
  primary: ThemeColor;
  secondary: ThemeColor;
}
export interface KineticTheme {
  [key: string]: Theme;
}

interface ColorTheme {
  [key: string]: ThemeColors;
}

export const preloadedColorThemes: ColorTheme = {
  default: {
    primary: {
      light: '#e2e8f0',
      main: brandBlue[4],
      dark: brandBlue[6],
      contrastText: '#fff',
    },
    secondary: {
      light: '#FDD835',
      main: '#F6B01F',
      dark: '#F6B01F',
      contrastText: '#444',
    },
  },
  lightBlue: {
    primary: {
      light: '#E1F5FE',
      main: '#01579B',
      dark: '#01579B',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc522',
      main: '#F6B01F',
      dark: '#FFB300',
      contrastText: '#444',
    },
  },
  cyan: {
    primary: {
      light: '#E0F7FA',
      main: '#00ACC1',
      dark: '#00ACC1',
      contrastText: '#fff',
    },
    secondary: {
      light: '#DB5F00',
      main: '#C25400',
      dark: '#B54E00',
      contrastText: '#fff',
    },
  },
  teal: {
    primary: {
      light: '#B2DFDB',
      main: '#00695C',
      dark: '#00695C',
      contrastText: '#fff',
    },
    secondary: {
      light: '#C29D0A',
      main: '#B59209',
      dark: '#9C7E08',
      contrastText: '#fff',
    },
  },
  lightGreen: {
    primary: {
      light: '#F1F8E9',
      main: '#33691E',
      dark: '#33691E',
      contrastText: '#fff',
    },
    secondary: {
      light: '#C25D2F',
      main: '#B5572B',
      dark: '#9C4B25',
      contrastText: '#fff',
    },
  },
  green: {
    primary: {
      light: '#E8F5E9',
      main: '#388E3C',
      dark: '#388E3C',
      contrastText: '#fff',
    },
    secondary: {
      light: '#E85F43',
      main: '#DB5A40',
      dark: '#C24F38',
      contrastText: '#fff',
    },
  },
  purple: {
    primary: {
      light: '#F3E5F5',
      main: '#4A138C',
      dark: '#4A138C',
      contrastText: '#fff',
    },
    secondary: {
      light: '#B74DC9',
      main: '#AB47BC',
      dark: '#943EA3',
      contrastText: '#fff',
    },
  },
  pink: {
    primary: {
      light: '#FCE4EC',
      main: '#F06292',
      dark: '#F06292',
      contrastText: '#fff',
    },
    secondary: {
      light: '#3F98B0',
      main: '#3B8DA3',
      dark: '#32778A',
      contrastText: '#fff',
    },
  },
  amber: {
    primary: {
      light: '#ECEFF1',
      main: '#FF8F00',
      dark: '#FF8F00',
      contrastText: '#fff',
    },
    secondary: {
      light: '#4DC1FF',
      main: '#00A4FF',
      dark: '#0085CC',
      contrastText: '#fff',
    },
  },
  brown: {
    primary: {
      light: '#D7CCC8',
      main: '#4E342E',
      dark: '#4E342E',
      contrastText: '#fff',
    },
    secondary: {
      light: '#5AE8B4',
      main: '#3D9C79',
      dark: '#338265',
      contrastText: '#fff',
    },
  },
  blueGray: {
    primary: {
      light: '#ECEFF1',
      main: '#546E7A',
      dark: '#546E7A',
      contrastText: '#fff',
    },
    secondary: {
      light: '#D4BF7D',
      main: '#C7B375',
      dark: '#AD9D66',
      contrastText: '#fff',
    },
  },
  minimal: {
    primary: {
      light: '#EEEEEE',
      main: '#E0E0E0',
      dark: '#E0E0E0',
      contrastText: '#fff',
    },
    secondary: {
      light: '#91A19E',
      main: '#859492',
      dark: '#6E7A79',
      contrastText: '#fff',
    },
  },
  dark: {
    mode: 'dark',
    primary: {
      light: '#303133',
      main: '#56c4e0',
      dark: '#202224',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FDD835',
      main: '#F6B01F',
      dark: '#F6B01F',
      contrastText: '#fff',
    },
  },
};

const grey = {
  '50': '#fafafa',
  '100': '#f4f4f4',
  '200': '#dddddd',
  '300': '#e2e8f0',
  '400': '#e0e0e0',
  '500': '#cbd5e0',
  '600': '#acbccd',
  '700': '#999999',
  '800': '#718096',
  '900': '#666666',
  A100: '#616161',
  A200: '#4a5568',
  A300: '#444444',
  A400: '#303030',
  A500: '#2d3748',
  A600: '#1a202c',
};

const darkModeGrey = {
  '50': '#fafafa',
  '100': '#303133',
  '200': '#303133',
  '300': '#ffffff',
  '400': '#333333',
  '500': '#222222',
  '600': '#acbccd',
  '700': '#999999',
  '800': '#718096',
  '900': '#666666',
  A100: '#101113',
  A200: '#4a5568',
  A300: '#444444',
  A400: '#303030',
  A500: '#2d3748',
  A600: '#1a202c',
  A700: '#111111',
  A800: '#444444',
};

export const breakpoints = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'] as Breakpoint[],
  values: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
};

const lightMode = {
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  grey,
};
const darkMode = {
  text: {
    primary: 'rgba(255, 255, 255, 0.87)',
    secondary: 'rgba(255, 255, 255, 0.54)',
    disabled: 'rgba(255, 255, 255, 0.38)',
    hint: 'rgba(255, 255, 255, 0.38)',
  },
  divider: 'rgba(255, 255, 255 0.12)',
  background: {
    paper: '#040404',
    default: '#212223',
  },
  grey: darkModeGrey,
};

const darkModeBorder = `1px solid ${darkModeGrey[700]}`;

const darkModeComponents = {
  MuiCard: {
    styleOverrides: {
      root: {
        backgroundColor: darkModeGrey['500'],
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        backgroundColor: darkModeGrey['500'],
        border: `1px solid ${darkModeGrey[900]}`,
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        border: darkModeBorder,
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        border: darkModeBorder,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        border: darkModeBorder,
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      popper: {
        border: darkModeBorder,
        borderRadius: 4,
      },
    },
  },
  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        border: darkModeBorder,
        borderRadius: 4,
      },
    },
  },
};

export const getTheme = ({ primary, secondary, mode = 'light' }: ThemeColors): ThemeOptions => ({
  breakpoints,

  spacing: 4,

  components: {
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          color: primary.contrastText,
        },
        colorSecondary: {
          color: secondary.contrastText,
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        input: {
          // makes the field have same height as medium button
          padding: 8.5,
        },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: text.secondary,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          [`&:hover:not(${inputBaseClasses['focused']})`]: {
            '&>svg': {
              color: 'inherit',
            },
          },
          [`&:not(${inputBaseClasses['focused']})`]: {
            '&>svg': {
              color: text.secondary,
            },
          },
          [`&${inputBaseClasses['focused']}`]: {
            '&>svg': {
              color: primary.main,
            },
          },
          '&.MuiInputBase-input': {
            '&:required': {
              backgroundColor: 'red',
            },
          },
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          [`&${switchClasses['disabled']}`]: {
            color: lighten(primary.main, 0.5),
          },
        },
        colorSecondary: {
          [`&${switchClasses['disabled']}`]: {
            color: lighten(secondary.main, 0.5),
          },
        },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'initial',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
        },
        root: {
          borderBottom: `1px solid ${grey[400]}`,
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        head: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        root: {
          '&:hover': {
            backgroundColor: grey[100],
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: alpha(grey[800], 0.9),
        },
      },
    },
    ...(mode === 'dark' && darkModeComponents),
  },

  visualizationPalette: {
    orange: '#FE8500',
    lightOrange: '#FFB000',
    green: '#34BC6E',
    lightGreen: '#95D13C',
    blue: '#5392FF',
    lightBlue: '#71CDDD',
    pink: '#FF509E',
    purple: '#9B82F3',
    red: '#EE3524',
    deepPurple: '#673AB7',
    indigo: '#3F51B5',
    cyan: '#00BCD4',
    teal: '#009688',
    lime: '#CDDC39',
    yellow: '#FFEB3B',
    amber: '#FFC107',
    brown: '#795548',
    blueGray: '#607D8B',
  },
  palette: {
    mode,
    common: {
      black: '#000',
      white: '#fff',
      reds: {
        map: {
          light: '#fd4646',
          main: '#f01616',
          dark: '#b03a3a',
          contrastText: '#fff',
        },
      },
    },
    primary: {
      ...primary,
      contrastText: '#fff',
    },
    secondary: {
      ...secondary,
      contrastText: '#fff',
    },
    error: {
      light: '#ed6e56',
      main: '#EE3524',
      dark: '#81200e',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#f68e1f',
      dark: '#f57c00',
      contrastText: '#fff',
    },
    success: {
      light: '#00FF28',
      main: '#00B247',
      dark: '#388e3c',
      contrastText: '#fff',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    ...(mode === 'light' ? lightMode : darkMode),
  },
  typography: {
    allVariants: {
      fontSize: 14,
    },
    h1: {
      fontSize: '1.5rem',
      letterSpacing: -0.25,
      lineHeight: 1,
      marginBlockEnd: 0,
      marginBlockStart: 0,
    },
    h2: {
      fontSize: '1.4rem',
      letterSpacing: -0.25,
      lineHeight: 1,
      marginBlockEnd: 0,
      marginBlockStart: 0,
    },
    h3: {
      fontSize: '1.3rem',
      letterSpacing: -0.5,
      lineHeight: 1,
      marginBlockEnd: 0,
      marginBlockStart: 0,
    },
    h4: {
      fontSize: '1.15rem',
      letterSpacing: -0.5,
      lineHeight: 1,
      marginBlockEnd: 0,
      marginBlockStart: 0,
    },
    h5: {
      fontSize: '1rem',
      lineHeight: 1,
      marginBlockEnd: 0,
      marginBlockStart: 0,
    },
    h6: {
      fontSize: '.875rem',
      lineHeight: 1,
      marginBlockEnd: 0,
      marginBlockStart: 0,
    },
    caption: {
      fontSize: 12,
    },
  },
});

export const KINETIC_THEMES = {} as KineticTheme;
Object.keys(preloadedColorThemes).forEach((key) => {
  KINETIC_THEMES[key] = createTheme(getTheme(preloadedColorThemes[key]));
});
