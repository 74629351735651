import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { createClient, PREFERENCE_KEYS } from '@kinetic-ui/shared';
import React, { memo, useEffect, useState } from 'react';
import { Client } from 'graphql-ws';
import { getUser } from 'utils/user';

import { ORG_CHANGE_EVENT, OrganizationChangeEvent } from './types';

const Apollo: React.FunctionComponent<any> = ({ children }) => {
  const [defaultOrganization, setDefaultOrganization] = useState<string>(
    sessionStorage.getItem(PREFERENCE_KEYS.DEFAULT_ORGANIZATION) ?? ''
  );

  const [apolloClient, setApolloClient] = useState<{
    websocket: Client;
    client: ApolloClient<NormalizedCacheObject>;
  }>();
  const handleOrgChange = (orgChangeEvent: CustomEvent<OrganizationChangeEvent>) => {
    setDefaultOrganization(orgChangeEvent.detail.organization);
  };

  useEffect(() => {
    window.addEventListener(ORG_CHANGE_EVENT, handleOrgChange as EventListener);
    return () => {
      window.removeEventListener(ORG_CHANGE_EVENT, handleOrgChange as EventListener);
    };
  }, []);

  useEffect(() => {
    let subscriptionInterval: NodeJS.Timeout;

    const getAccessToken = async () => {
      const currentUser = getUser();
      if (!currentUser) {
        return '';
      }
      return currentUser.access_token;
    };

    const refreshClient = async () => {
      const response = await createClient(defaultOrganization, getAccessToken);
      if (!response) {
        return;
      }
      setApolloClient((prev) => {
        setTimeout(() => {
          // Cancel the previous after a timeout to ensure we don't miss messages before the next render of the new client
          prev?.client.stop();
          prev?.websocket.dispose();
        }, 100);
        return response;
      });
      subscriptionInterval = response.subscriptionInterval;
    };

    refreshClient();
    return () => subscriptionInterval && clearInterval(subscriptionInterval);
  }, [defaultOrganization]);

  return apolloClient ? (
    <ApolloProvider client={apolloClient.client}>{children}</ApolloProvider>
  ) : null;
};

export default memo(Apollo);
