import {
  ApolloClient,
  ApolloLink,
  DefaultContext,
  from,
  HttpLink,
  HttpOptions,
  InMemoryCache,
  ServerError,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient as createWsClient } from 'graphql-ws';
import { OperationDefinitionNode } from 'graphql/language/ast';
import { CustomBatchHttpLink } from 'types/api/CustomBatchHttpLink';

export enum GraphQlProps {
  URI = 'graphql',
  SUBSCRIPTIONS = 'subscriptions',
}

export const SESSION_CHECK_KEY = 'sessionCheckFailed';

let httpUri: string;
try {
  httpUri = process.env.REACT_APP_GRAPHQL_API!;
} catch (error) {
  httpUri = `${window.location.origin}/${GraphQlProps.URI}/`;
}

const httpUrl = new URL(httpUri);

const wsUri = `${httpUrl.protocol === 'https:' ? 'wss:' : 'ws:'}//${httpUrl.host}/${
  GraphQlProps.SUBSCRIPTIONS
}`;
const keepAliveMaxRequestSize = 60 * 1024;

export const getXrsfTokenFromCookie = () => {
  const cookies = document.cookie.split(';');
  const xsrfCookieIndex = cookies.findIndex((cookie) => cookie.includes('XSRF-TOKEN'));
  if (xsrfCookieIndex === -1) {
    console.warn('Could not find a xsrf token');
    return '';
  }
  return cookies[xsrfCookieIndex].split('=')[1];
};

export const createClient = async (
  organization: string,
  accessTokenGetter?: () => Promise<string>
) => {
  const cache = new InMemoryCache({
    typePolicies: {
      Resource: {
        keyFields: false,
      },
      GateOperationStep: {
        keyFields: false,
      },
    },
  });
  // NOTE: Disabled local storage cache persistence for testing
  // await before instantiating ApolloClient, else queries might run before the cache is persisted
  // await persistCache({
  //   cache,
  //   // storage: new LocalStorageWrapper(window.localStorage),
  //   key: `apollo-cache-persist-${organization}`,
  // });

  const xsrfToken = getXrsfTokenFromCookie();

  const headers: Record<string, string> = {
    'X-XSRF-TOKEN': xsrfToken,
  };

  if (organization) {
    headers['X-Tenant'] = organization;
  }

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    const permissionFailStatusCodes = [401, 403];
    const statusCode = (networkError as ServerError)?.statusCode;
    if (statusCode && permissionFailStatusCodes.includes(statusCode)) {
      sessionStorage.setItem(SESSION_CHECK_KEY, 'true');
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const subscriptionUrl = `${wsUri}`;
  // set the header on the web socket link;
  const connectionParams = async () => ({
    accessToken: accessTokenGetter ? await accessTokenGetter() : undefined,
    'X-Tenant': organization ?? '',
  });
  let activeSocket: WebSocket;
  const subscriptionClient = createWsClient({
    url: subscriptionUrl,
    connectionParams,
    keepAlive: 30_000,
    shouldRetry: () => {
      return true;
    },
    on: {
      opened: (socket) => (activeSocket = socket as WebSocket),
      closed: (socket) => {
        console.warn('socket closed', socket);
      },
      error: (socket) => {
        console.error('socket error', socket);
      },
    },
  });
  const wsLink = new GraphQLWsLink(subscriptionClient);
  const refreshSubscriptions = async () => {
    if (activeSocket?.readyState === 1) {
      const message = { type: 'refresh_token', payload: await connectionParams() };
      activeSocket.send(JSON.stringify(message));
    }
  };
  const subscriptionInterval = setInterval(refreshSubscriptions, 60000);

  const headerLink = setContext(async (_request, previousContext) => {
    const accessToken = accessTokenGetter ? await accessTokenGetter() : undefined;
    return {
      headers: {
        // Make sure you include any existing headers!
        ...previousContext.headers,
        authorization: `Bearer ${accessToken}`,
      },
    };
  });

  const fetchOptionsLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ keepAlive, ...otherProps }: DefaultContext) => {
      const fetchOptions: HttpOptions['fetchOptions'] = {};
      if (keepAlive) {
        const requestBody = JSON.stringify({
          query: operation.query,
          variables: operation.variables,
        });
        const requestBodySize = new TextEncoder().encode(requestBody).length;
        if (requestBodySize <= keepAliveMaxRequestSize) {
          // Chrome has a limit of 64KB for keepalive requests
          fetchOptions.keepalive = true;
        }
      }

      return { fetchOptions, ...otherProps };
    });
    return forward(operation);
  });

  // create http link
  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    from([
      errorLink,
      fetchOptionsLink,
      headerLink.concat(
        split(
          ({ getContext }) => {
            return !!getContext().batch;
          },
          new CustomBatchHttpLink({
            headers,
            uri: httpUri,
          }),
          new HttpLink({
            headers,
            uri: httpUri,
          })
        )
      ),
    ])
  );

  return {
    client: new ApolloClient({
      cache,
      link,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      } as const,
    }),
    websocket: subscriptionClient,
    subscriptionInterval,
  };
};
