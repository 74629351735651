import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import account from './en/account.json';
import app from './en/app.json';
import assetmanagement from './en/assetmanagement.json';
import common from './en/common.json';
import communicationlogs from './en/communicationlogs.json';
import connectedVehicles from './en/connectedvehicles.json';
import contacts from './en/contacts.json';
import corridors from './en/corridors.json';
import detectors from './en/detectors.json';
import emails from './en/emails.json';
import events from './en/events.json';
import fleet from './en/fleet.json';
import gates from './en/gates.json';
import integrations from './en/integrations.json';
import lastterm from './en/lastterm.json';
import location from './en/location.json';
import locations from './en/locations.json';
import maps from './en/maps.json';
import parking from './en/parking.json';
import reports from './en/reports.json';
import rampMeter from './en/ramp-meter.json';
import roadConditions from './en/road-conditions.json';
import roadassistance from './en/roadassistance.json';
import scheduler from './en/scheduler.json';
import sessionDialog from './en/sessionDialog.json';
import signals from './en/signals.json';
import signs from './en/signs.json';
import timeSpaceDiagram from './en/timeSpaceDiagram.json';
import timezone from './en/timezone.json';
import travelTime from './en/travel-time.json';
import tunnels from './en/tunnels.json';
import video from './en/video.json';
import weather from './en/weather.json';
import workspace from './en/workspace.json';
import reportBuilder from './en/report-builder.json';

// Please keep these alphabetical
export const resources = {
  en: {
    account,
    app,
    assetmanagement,
    common,
    communicationlogs,
    contacts,
    corridors,
    detectors,
    emails,
    events,
    fleet,
    gates,
    integrations,
    lastterm,
    location,
    locations,
    maps,
    reports,
    roadassistance,
    scheduler,
    sessionDialog,
    signals,
    signs,
    parking,
    rampMeter,
    roadConditions,
    timeSpaceDiagram,
    timezone,
    'travel-time': travelTime,
    'report-builder': reportBuilder,
    tunnels,
    video,
    weather,
    workspace,
    connectedVehicles,
  },
} as const;

export const initI18n = () => {
  i18n.use(initReactI18next).init({
    lng: 'en',
    resources,
  });
};

export default i18n;
