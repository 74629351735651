import { EntityTypes } from 'const';
import {
  KineticEffectivePrivilegeAssignment,
  KineticModule,
  KineticUserQuery,
} from '@kinetic-ui/data-layer';
import React from 'react';
import { Maybe, Nullable } from 'types';

export type NullableUser = Nullable<
  Omit<KineticUserQuery['kineticUser'], '__typename' | 'preferences' | 'kineticPreferences'>
>;
export type UnitType = 'imperial' | 'metric';

export type RestrictedEntities = Record<EntityTypes, string[]>;

type EffectivePrivilegeAssignment = Omit<
  KineticEffectivePrivilegeAssignment,
  'restrictedEntities'
> & {
  restrictedEntities?: Maybe<RestrictedEntities>;
};

export type Privileges = Record<string, Record<string, EffectivePrivilegeAssignment[]>>;

export interface UserContextData extends NullableUser {
  ready: boolean;
  privileges: Privileges;
  unit: UnitType;
  modules: KineticModule[];
}

export const UserContext = React.createContext<UserContextData>({
  id: null,
  username: null,
  email: null,
  firstName: null,
  lastName: null,
  picture: null,
  ready: false,
  privileges: {},
  unit: 'metric',
  modules: [],
});
