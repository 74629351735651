import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useEnter, useEscape, useInterval, useKeyPresses } from '@qfa/react-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SESSION_CHECK_KEY } from '@kinetic-ui/shared';
import { SessionCheckProps } from './types';

const SessionCheck: React.FunctionComponent<SessionCheckProps> = (_props) => {
  const { t } = useTranslation('sessionDialog');

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    sessionStorage.setItem(SESSION_CHECK_KEY, 'false');
  }, []);

  useInterval(
    () => {
      const sessionCheckStatus = sessionStorage.getItem(SESSION_CHECK_KEY);
      if (sessionCheckStatus === 'true') {
        setIsOpen(true);
      }
    },
    isOpen ? null : 1000
  );

  const reload = () => {
    let devHost = '';
    try {
      devHost = process.env.REACT_APP_API_HOST ?? '';
    } catch (e) {
      devHost = '';
    }

    sessionStorage.removeItem(SESSION_CHECK_KEY);
    if (process.env.NODE_ENV === 'development') {
      window.open(devHost);
    } else {
      window.location.reload();
    }
  };

  const onEscape = useEscape({ callback: reload });
  const onEnter = useEnter({ callback: reload });
  const onKeyPress = useKeyPresses(onEnter, onEscape);

  const renderSessionCheck = () => {
    if (!isOpen) {
      return null;
    }

    return (
      <Dialog open={isOpen} onKeyUp={onKeyPress}>
        <DialogTitle>{t('title')}</DialogTitle>
        <DialogContent>
          <Typography>{t('content')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={reload}>
            {t('login')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return renderSessionCheck();
};

export default SessionCheck;
